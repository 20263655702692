/*
Toggle display of content for
*/
(function($) {


// dine/stay page results
  var $toggle_content = $('.resultblock .toggle-content');

  // hide descriptions on page load
  $('.resultblock .desc').hide();

  // toggle page result descriptions
  $toggle_content.click(function(evt){

    evt.preventDefault();
    //console.log('click');
    var $this = $(this);
    if ( $this.hasClass('open') ) {
      $this.removeClass('open');
      $this.text('MORE INFO');
      $this.prev('i').removeClass('fa-minus-circle').addClass('fa-plus-circle');
      $this.closest('p').next('.desc').slideUp();
    } else {
      $this.addClass('open');
      $this.text('CLOSE');
      $this.prev('i').removeClass('fa-plus-circle').addClass('fa-minus-circle');
    $this.closest('p').next('.desc').slideDown();
    }

  });


// arts landing page

    // var
    //var $arts_btn_map = $('#arts-browse-toggle__btn-map');
    //var $arts_btn_dir = $('#arts-browse-toggle__btn-dir');
    //var $arts_map = $('#arts-map');
    //var $arts_dir = $('#arts-dir');
    //var $arts_browse_wrap = $('#arts-browse-toggle');

    // action
    $('#arts-browse-toggle a').each(function(){

      $(this).click(function(evt){

        evt.preventDefault();
        var $this = $(this);
        var target_open = $this.attr('href');
        var target_close = $this.data('close');
        //console.log('click');
        //console.log(target_open);
        //console.log(target_close);

        if ( $this.hasClass('js-open') ) {
          // do nothing
        } else {
          $('#arts-browse-toggle').find('a.js-open').removeClass('js-open');
          $this.addClass('js-open');
          $('#'+target_close).slideUp();
          $(target_open).slideDown();
        }

      });

    });

    // init
    $('#arts-browse-toggle__btn-map').trigger('click');

// quilt trail landing page
/*
    // action
    $('#quilt-browse-toggle a').each(function(){

      $(this).click(function(evt){

        evt.preventDefault();
        var $this = $(this);
        var target_open = $this.attr('href');
        var target_close = $this.data('close');
        //console.log('click');
        //console.log(target_open);
        //console.log(target_close);

        if ( $this.hasClass('js-open') ) {
          // do nothing
        } else {
          $('#quilt-browse-toggle').find('a.js-open').removeClass('js-open');
          $this.addClass('js-open');
          $('#'+target_close).slideUp();
          $(target_open).slideDown();
        }

      });

    });

    // init
    $('#quilt-browse-toggle__btn-map').trigger('click');
*/

// subnav

/*
  let $subnav_toggle = $('.subnav-toggle');

  // toggle page result descriptions
  $subnav_toggle.click(function(evt){

  evt.preventDefault();
  //console.log('click');

  let $this = $(this);
  if ( $this.hasClass('open') ) {
    $this.removeClass('open');
    $this.next('.subnav-toggle-content').addClass('collapse').slideDown();
  } else {
    $this.addClass('open');
    $this.next('.subnav-toggle-content').removeClass('collapse').slideUp();
  }

});
*/


  $('.subnav-toggle').each(function() {
    var $toggle = $(this);
    var $content = $toggle.next('.subnav-toggle-content');

    // Function to check and set the initial state based on viewport
    function checkAndSetInitialState() {
      if (window.matchMedia('(min-width: 640px)').matches) {
        $content.show(); // Show content in medium or larger devices
      } else {
        $content.hide(); // Hide content in small devices
      }
    }

    // Call the function to set initial state
    checkAndSetInitialState();

    // Function to handle window resize
    function handleResize() {
      if (window.matchMedia('(max-width: 640px)').matches) {
        $content.hide();
        $toggle.removeClass('open');
      } else {
        $content.show(); // Show content in medium or larger devices
      }
    }

    // Attach resize event listener
    $(window).resize(handleResize);

    // Function to toggle visibility on click
    function toggleVisibility(evt) {
      evt.preventDefault();
      if ($content.is(':visible')) {
        $content.slideUp();
        $toggle.removeClass('open');
      } else {
        $content.slideDown();
        $toggle.addClass('open');
      }
    }

    // Attach click event to toggle visibility
    $toggle.click(toggleVisibility);
  });



})(jQuery);
