/*
Bulletins
/template-parts/acf-module-bulletins.php
*/
(function($) {

  var $bulletin = $('.bulletin-row');
  var $bulletin_content = $('#bulletin-content');

  // show/hide bulletin content
  //$bulletin_content.hide();
  //$bulletin_content.slideUp();
  //$bulletin_content.addClass('close');

  $('#toggle-bulletin-content').click(function(evt) {
    evt.preventDefault();
    $bulletin_content.removeClass('init');

    if( $(this).hasClass('open') ) {
      $(this).removeClass('open').text('Read more');
      //$bulletin_content.slideUp();
      $bulletin_content.addClass('close');
    } else {
      $(this).addClass('open').text('Close');
      //$bulletin_content.slideDown();
      $bulletin_content.removeClass('close');
    }

  });
  //$('#toggle-bulletin-content').trigger('click');

  // close bulletin row
  /*
  $('.close-bulletin-btn').click(function(evt) {
    evt.preventDefault();
    $(this).closest('.bulletin-row').hide();
  });
  */


})(jQuery);
