/*
Mobile Nav toggle
/header.php
*/
(function($) {

  // page smooth scrolling
	$(".scroller").click(function(evt){
    evt.preventDefault();
    //console.log($(this).attr('href'));
		$('html, body').animate( { scrollTop: $( $(this).attr('href')).offset().top - 20}, 500 );
	});


	// page smooth scrolling
	$(".introscrolldown").click(function(evt){
    evt.preventDefault();
		console.log('click');
		$('html, body').animate( { scrollTop: $(this).offset().top + 150}, 500 );
	});

  // back to top
	var bodyheight = $("body").outerHeight();
	var didScroll;
	var targetheight = bodyheight/4;
  var $backtotop = $('#backtotop');
  $backtotop.addClass('inactive');

	// waypoint
  $('#page-top').waypoint(function(direction) {
    if (direction === 'down') {
      $backtotop.removeClass('inactive');
    }
    else {
      $backtotop.addClass('inactive');
    }
  }, {
    offset: '-30%'
  });

	// on scroll, let the interval function know the user has scrolled
	$(window).scroll(function(event){
	  didScroll = true;
	});

	setInterval(function() {
	  if (didScroll) {
			$backtotop.addClass('scrolling');
			$('body').addClass('scrolling');
			didScroll = false;
	  } else {
			$backtotop.removeClass('scrolling');
			$('body').removeClass('scrolling');
		}
	}, 500);



	// main nav behavior; but not on home page medium+
	if( $('body').hasClass('home') && Foundation.MediaQuery.atLeast('medium') ) {

	} else {

		// sticky nav
		var stickynav = new Waypoint.Sticky({
			element: $('#pgheader')[0]
		});

		// body scrolled; used to collapse main nav
	  var $body = $('body');
		var body = document.getElementsByTagName("body");
	  var pgheaderWaypoint = new Waypoint({
			element: body[0],
	    handler: function(direction) {

	      if (direction === 'down') {
	        $body.addClass('bodyscrolled');
	      }
	      else {
	        $body.removeClass('bodyscrolled');
	      }

	    },
	    offset: '-10%'
	  });

		// toggle nav
	  var $toggleDesktopNav = $('#toggle-desktop-nav');
	  $toggleDesktopNav.click(function(evt) {
	    evt.preventDefault();

	    // remove waypoint
	    $body.removeClass('bodyscrolled').addClass('bodywaypointoff');
	    pgheaderWaypoint.destroy();

	    // actions
	    if ( $(this).hasClass('open') ) {
	      $(this).removeClass('open');
	      $body.addClass('pghdrcollapse');
				//$('.sticky-wrapper').css('height','68px');
				$('.sticky-wrapper').animate({height: "72"}, 150);
	    } else {
	      $(this).addClass('open');
	      $body.removeClass('pghdrcollapse');
				//$('.sticky-wrapper').css('height','168px');
				$('.sticky-wrapper').animate({height: "168"}, 150);
	    }

	  });

	}

	// resize nav when changing between medium and large breakpoints
	setInterval(function() {

		$(window).on('changed.zf.mediaquery', function(event, newSize, oldSize){

			var $stickywrapper = $('.sticky-wrapper');

			// large to medium
			if(newSize == 'medium' && oldSize == 'large') {
				//console.log('large to medium');

				if ($stickywrapper.length) {
					$stickywrapper.height(72);
				}

			}

			// medium to large
			if(newSize == 'large' && oldSize == 'medium') {
				//console.log('medium to large');

				if ($stickywrapper.length) {
					$stickywrapper.height(168);
				}

			}

		});//on

	}, 500);


	// North Coast Food Trail - cancel
	if( $('body').hasClass('page-id-7260') ) {

    $('html, body').animate( { scrollTop: $('.callout').offset().top - 140}, 500 );

  }

	// North Coast Food Trail - success
	if( $('body').hasClass('page-id-7262') ) {

    $('html, body').animate( { scrollTop: $('.callout').offset().top - 140}, 500 );

  }

	// Tillamook Coast Books - success
	if( $('body').hasClass('page-id-7250') ) {

    $('html, body').animate( { scrollTop: $('.callout').offset().top - 140}, 500 );

  }

	// Tillamook Coast Books - cancel
	if( $('body').hasClass('page-id-7249') ) {

    $('html, body').animate( { scrollTop: $('.callout').offset().top - 140}, 500 );

  }


})(jQuery);
