/*
Customize gravity form
*/
(function($) {

  // request a guide form: scroll page if message
  var $form_guide_request = $('#gform_3');
  if( $form_guide_request.find('.validation_error').length ) {

    $('html, body').animate( { scrollTop: $form_guide_request.offset().top - 120}, 500 );

  }
  var $form_guide_request_success = $('#gform_confirmation_wrapper_3');
  if( $form_guide_request_success.length ) {

    $('html, body').animate( { scrollTop: $form_guide_request_success.offset().top - 120}, 500 );

  }

  // contact page: scroll page if message
  var $form_contact = $('#gform_8');
  if( $form_contact.find('.validation_error').length ) {

    $('html, body').animate( { scrollTop: $form_contact.offset().top - 120}, 500 );

  }
  var $form_contact_success = $('#gform_confirmation_wrapper_8');
  if( $form_contact_success.length ) {

    $('html, body').animate( { scrollTop: $form_contact_success.offset().top - 120}, 500 );

  }

  // contest page: scroll page if message
  var $form_contest = $('#gform_16');
  if( $form_contest.find('.validation_error').length ) {

    $('html, body').animate( { scrollTop: $form_contest.offset().top - 120}, 500 );

  }
  var $form_contest_success = $('#gform_confirmation_wrapper_16');
  if( $form_contest_success.length ) {

    $('html, body').animate( { scrollTop: $form_contest_success.offset().top - 120}, 500 );

  }

  // waterway page: scroll page if message
  var $form_waterway = $('#gform_2');
  if( $form_waterway.find('.validation_error').length ) {

    $('html, body').animate( { scrollTop: $form_waterway.offset().top - 120}, 500 );

  }
  var $form_waterway_success = $('#gform_confirmation_wrapper_2');
  if( $form_waterway_success.length ) {

    $('html, body').animate( { scrollTop: $form_waterway_success.offset().top - 120}, 500 );

  }

  // foodtrail page: scroll page if message
  var $form_foodtrail = $('#gform_15');
  if( $form_foodtrail.find('.validation_error').length ) {

    $('html, body').animate( { scrollTop: $form_foodtrail.offset().top - 120}, 500 );

  }
  var $form_foodtrail_success = $('#gform_confirmation_wrapper_15');
  if( $form_foodtrail_success.length ) {

    $('html, body').animate( { scrollTop: $form_foodtrail_success.offset().top - 120}, 500 );

  }

  // meeting page: scroll page if message
  var $form_meeting = $('#gform_9');
  if( $form_meeting.find('.validation_error').length ) {

    $('html, body').animate( { scrollTop: $form_meeting.offset().top - 120}, 500 );

  }
  var $form_meeting_success = $('#gform_confirmation_wrapper_9');
  if( $form_meeting_success.length ) {

    $('html, body').animate( { scrollTop: $form_meeting_success.offset().top - 120}, 500 );

  }

})(jQuery);
