(function($) {

// safe email
	// if data-config="mail" turn into an mailto: link
	// assume html of
	// <span class="safe-email" data-config="mail"><span class="safe-email-title">Webmaster</span> <span class="safe-email-target">(info[at]cardwellcreative[dot]com)</span></span>
	// outputs
	// <span class="safe-email" data-config="mail"><a href="mailto:info@cardwellcreative.com">Webmaster</a></span>

	 $('.safe-email').each(function() {

		var $this = $(this);

		// get email target
		var $target = $this.find('.safe-email-target');

		// if missing email selector: add error class and exit script
		if ( $target.length === 0 )
		{
			$this.addClass('error-missing-target');
			return;
		}

		var config = $this.data('config');

		// if config set to mail, build mailto link
		if ( config == 'mail' )
		{
			// get email address
			var email_address_raw = $target.text();

			// convert email address characters: [at] to @, and [dot] to ., and () to empty
			var email_address = email_address_raw.replace("[at]", "@").replace("[dot]", ".").replace("(", "").replace(")", "");

			// get title
			var $title_selector = $this.find('.safe-email-title');
			var title;
			if ( $title_selector.length > 0 )
			{
				title = $title_selector.text();
			}
			else
			{
				// if missing title selector, use email address
				title = email_address;
			}

			// output mailto link
			$this.html('<a href="mailto:'+ email_address +'" target="_blank">'+ title +'</a>');
		}

});

})(jQuery);
