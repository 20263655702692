/*
readmore show/hide
*/
(function($) {

  $('.more').hide();
	$('.readmore').on('click', function(event) {
		event.preventDefault();
		var target = $(this).data('target');

		if( $(this).hasClass('open') ) {

			$('#'+target).removeClass('open').slideUp('fast');
			$(this).text('Read more').removeClass('open');

		} else {

			$('#'+target).slideDown('fast', function(){
        $(this).addClass('open');
      });
			$(this).text('Collapse').addClass('open');

		}

  });

})(jQuery);
