/*
filters for dine/stay pages
dine.php, stay.php
*/
(function($) {

  $('.category').on('change', function(){

    var category_list = [];
    $('#filters :input:checked').each(function(){
      var category = $(this).val();
      category_list.push(category); //Push each check item's value into an array
      });

    if(category_list.length === 0)
      $('.resultblock').fadeIn();
    else {
      $('.resultblock').each(function(){
        var item = $(this).attr('data-tag');
        //console.log(item);
        var itemarr = item.split(',');
        var $this = $(this);
        $.each(itemarr,function(ind,val){
        //console.log("Val='"+val+"'");
          if(jQuery.inArray(val,category_list) > -1){
            $this.fadeIn('slow');
            return false;
          }
          else
            $this.hide();
        });
      });
    }

    $('html, body').animate( { scrollTop: $('#col-results').offset().top - 50}, 1000 );

  });

  $('.categoryradio').on('change',function(){

    var checkedValue=$('#filtersradio :input:checked').val();
    //console.log(checkedValue);

    if(checkedValue == 'filter_showall')
      $('.resultblock').fadeIn();
    else {
      $('.resultblock').each(function(){
        var item = $(this).attr('data-tag');
        //console.log(item);
        var itemarr = item.split(',');
        var $this = $(this);
        $.each(itemarr,function(ind,val){
        //console.log("Val='"+val+"'");
          if(val===checkedValue){
            $this.fadeIn('slow');
            return false;
          }
          else
            $this.hide();
        });
      });
    }

    $('html, body').animate( { scrollTop: $('#col-results').offset().top - 50}, 1000 );

  });


  // filters show/hide
  var window_width = $('body').width();
  var $toggle_amenity = $("#amenity-filters").find('h3');
  var $toggle_content_amenity = $("#filtersradio");

  if ( window_width < 640 ) {

    $toggle_amenity.addClass('toggle');
    $toggle_content_amenity.slideUp();

    $toggle_amenity.click(function() {

      if( $(this).hasClass('open') ) {
        $(this).removeClass('open');
        $toggle_content_amenity.slideUp();
      } else {
        $(this).addClass('open');
        $toggle_content_amenity.slideDown();
      }

    });

  }


})(jQuery);
