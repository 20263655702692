/*
Matrix Grid
/template-parts/acf-module-resource-grid.php
*/
(function($) {

  // setup
	var $matrix = $('.matrix-group');
	$matrix.find('.matrix-info').hide();

	// show/hide
	$matrix.find('.matrix').each( function() {

		$(this).click(function(evt) {
			evt.preventDefault();
			var target = $(this).data('target');

			if ( $(this).hasClass('open') )
			{
				$(this).removeClass('open');
				$('#'+target).slideUp();
			} else {
				$matrix.find('.matrix.open').removeClass('open');
				$matrix.find('.matrix-info:visible').hide();
				$(this).addClass('open');
				$('#'+target).slideDown();
			}
		});

	});

	// click first item
	$matrix.find('.matrix').first().trigger('click');

	// close
	$matrix.find('.close').each( function() {

		$(this).click(function(evt) {
			evt.preventDefault();
			var target = $(this).data('target');
			$('#'+target).slideUp().prev().removeClass('open');
		});

	});



})(jQuery);
