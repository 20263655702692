/*
Toggle display of content for dine/stay page results
*/
(function($) {

// play video on modal load
$(document).on('open.zf.reveal', '[data-reveal]', function() {
  var $video_iframe = $(this).find('iframe');
  if ($video_iframe.length) {
    $video_iframe.attr('src', $video_iframe.attr('src')+'&autoplay=1');
  }
  //console.log( 'opened' );
});

// stop video on modal close
$(document).on('closed.zf.reveal', '[data-reveal]', function() {
  var $video_iframe = $(this).find('iframe');
  if ($video_iframe.length) {
    var video_src = $video_iframe.attr('src');
    video_src = video_src.replace("&autoplay=1", "");
    $video_iframe.attr('src','');
    $video_iframe.attr('src',video_src);
  }
  //console.log( 'closed' );
});


// grant's getaway
/*
$('#leadvideo').on('click', function(event) {

	$(this).find('iframe')[0].src += "&autoplay=1";
	setTimeout( function(){ $('#leadvideo').addClass('activated'); }  , 500);

});
*/
//$('#leadvideo').find('iframe')[0].src += "?rel=0&amp;showinfo=0";
//$('#leadvideo').find('iframe')[0].src += "&amp;rel=0&amp;showinfo=0";
$('.grantvideo').each(function(index) {
  $(this).find('iframe')[0].src += "?rel=0&amp;showinfo=0";
});


// launch modal on homepage
/*
if( $('body').hasClass('home') ) {

  // auto open modal
  setTimeout(function(){
    $('#modal-travel').foundation('open').addClass('autoload');
  }, 250);

  // on close modal
  $(document).on('closed.zf.reveal', '#modal-travel', function() {

    if( $(this).hasClass('autoload') ) {
      $("html, body").animate({ scrollTop: 0 }, 1);
      $(this).removeClass('autoload');
    }

  });


}
*/

})(jQuery);
