/*
/template-parts/menu-archive.php
*/
(function($) {

// Archive template dropdown menu action

  var $archive_menu = $('#blog-filter-wrap');

  // hide submit input
  $archive_menu.find('input:submit').hide();

  // submit form on select form field change
  $archive_menu.find('select').each(function(index) {

    $(this).on('change', function() {
      //$(this).closest('form').find('input:submit').trigger('click');

      if( $(this).hasClass('author') ) {
        var author_target = $(this).val();
        if (author_target == 'all') {
          location.href = window.location.protocol+'//'+window.location.host+"/?p=5807";
        } else {
          location.href = window.location.protocol+'//'+window.location.host+"?author="+$(this).val();
        }
        //console.log( window.location.protocol+'//'+window.location.host+"?author="+$(this).val() );
        //console.log( window.location.protocol+'//'+window.location.host+"/author/"+$(this).val() );
      }

      if( $(this).hasClass('cat') ) {
        var cat_target = $(this).val();
        if (cat_target == 'all') {
          location.href = window.location.protocol+'//'+window.location.host+"/?p=5807";
        } else {
          location.href = window.location.protocol+'//'+window.location.host+"?cat="+$(this).val();
        }
      }


    });


  });

// toggle filter/search forms

  $(".blog-toggle-content").hide();

  $("#blog-toggles button").click(function(evt) {
		evt.preventDefault();
		var target = $(this).data('target');
    console.log(target);

		if ( $(this).hasClass('active') )
		{
			$(this).removeClass('active');
			$('#'+target).slideUp();
		} else {
      $('button.active').removeClass('active');
      $('.blog-toggle-content:visible').hide();
			$(this).addClass('active');
			$('#'+target).slideDown();
		}

	});


})(jQuery);
