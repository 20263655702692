/*
tracking scripts
*/
(function($) {


 if( $('body').hasClass('livesite') ) {

   // get the visitor guide form; /gettheguide
   $('#gform_submit_button_3').click(function(){
     ga('send', 'event', 'button', 'click', 'request guide');
   });

   // constant contact subcribe form; footer activated modal
   /*
   $('#ctct_form_0 button[type="submit"]').click(function(){
     ga('send', 'event', 'button', 'click', 'join newsletter');
   });
   */
    /*
    $('#ctct_form_0 button[type="submit"]').click(function(){
      ga('send', 'event', 'button', 'click', 'join newsletter', {'transport': 'beacon'});
     });
     */


   // waterway map request: /kayaking
   $('#gform_submit_button_2').click(function(){
     ga('send', 'event', 'button', 'click', 'Get Water Trail Map');
   });

   // meeting form: /meeting-request
   $('#gform_submit_button_9').click(function(){
     ga('send', 'event', 'button', 'click', 'meeting requests');
   });

   // Book your stay;
   /*
   $('#jrs-button-1309').click(function(){
     ga('send', 'event', 'button', 'click', 'book now');
   });
   */

    /*
    $('#jrs-button-1309').click(function(){
      ga('send', 'event', 'button', 'click', 'book now', {'transport': 'beacon'});
    });
    */

    /*
    $('#jrs-button-1309').click(function(){

      console.log('book now click');

      ga('send', 'event', 'button', 'click', 'book now', {
      'transport': 'beacon',
       'hitCallback': function() {
         console.log('book now ga click sent');
       }
      });

     });
     */

 }// end if


})(jQuery);
