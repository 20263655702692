/* Tribe Events filter change popup */

(function($) {

  var $filterbarform = $('#tribe_events_filters_form');

  $filterbarform.on( 'change', 'input, select', function() {

    $('#tribe-events-content').prepend(
      '<div class="event-filter-popup">Filtering...</div>'
    );

  });

})(jQuery);
