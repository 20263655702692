

(function($) {

  //https://css-tricks.com/snippets/jquery/image-preloader/
  $.preloadImages = function() {
    for (var i = 0; i < arguments.length; i++) {
      $("<img />").attr("src", arguments[i]);
    }
  };

  // preload images
  $("#home-seasons .photo-wrap").each(function( index ) {
    //console.log( $(this).data('url') );
    $.preloadImages( $(this).data('url') );
  });

  // remove title and related videos from youtube embeds
  var $video_row_iframe = $('.video-row iframe,.reveal-video-modal iframe');
  $video_row_iframe.attr('src',$video_row_iframe.attr('src')+'?rel=0&amp;showinfo=0');


})(jQuery);
