
(function($) {

  // init Masonry
  var $grid = $('.tmkmasonry').masonry({
    itemSelector: '.columns',
    columnWidth: '.grid-sizer'
  });
  // layout Masonry after each image loads
  $grid.imagesLoaded().progress( function() {
    $grid.masonry('layout');
  });

})(jQuery);
