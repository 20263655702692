/*
slideshows
*/
(function($) {

  // Related Pages slideshow
  $("#related-content-slideshow").slick({
  	dots: false,
  	arrows: true,
  	infinite: false,
  	speed: 300,
  	slidesToShow: 3,
  	slidesToScroll: 1,
    //prevArrow: '<button type="button" class="slick-prev">Previous</button>',
    //nextArrow: '<button type="button" class="slick-next">Next</button>',
    responsive: [
    {
  	  breakpoint: 1024,
  	  settings: {
        dots: false,
      	arrows: true,
      	slidesToShow: 2,
      	slidesToScroll: 1,
  	  }
  	},
  	{
  	  breakpoint: 640,
  	  settings: {
    		//dots: true,
        //arrows: false,
        dots: false,
      	arrows: true,
    		slidesToShow: 1,
    		slidesToScroll: 1,
  	  }
  	}
  	]
  });

  // Related Pages slideshow
  $("#related-page-slideshow").slick({
  	dots: false,
  	arrows: true,
  	infinite: false,
  	speed: 300,
  	slidesToShow: 3,
  	slidesToScroll: 1,
    //prevArrow: '<button type="button" class="slick-prev">Previous</button>',
    //nextArrow: '<button type="button" class="slick-next">Next</button>',
    responsive: [
    {
  	  breakpoint: 1024,
  	  settings: {
        dots: false,
      	arrows: true,
      	slidesToShow: 2,
      	slidesToScroll: 1,
  	  }
  	},
  	{
  	  breakpoint: 640,
  	  settings: {
    		//dots: true,
        //arrows: false,
        dots: false,
      	arrows: true,
    		slidesToShow: 1,
    		slidesToScroll: 1,
  	  }
  	}
  	]
  });

  // Related Posts slideshow
  $("#related-post-slideshow").slick({
  	dots: false,
  	arrows: true,
  	infinite: false,
  	speed: 300,
  	slidesToShow: 3,
  	slidesToScroll: 1,
  	responsive: [
    {
  	  breakpoint: 1024,
  	  settings: {
        dots: false,
      	arrows: true,
      	slidesToShow: 2,
      	slidesToScroll: 1,
  	  }
  	},
  	{
  	  breakpoint: 640,
  	  settings: {
        //dots: true,
        //arrows: false,
        dots: false,
      	arrows: true,
    		slidesToShow: 1,
    		slidesToScroll: 1,
  	  }
  	}
  	]
  });

  // Carousel slideshow
  $(".photo-carousel").slick({
  	dots: false,
  	arrows: true,
  	infinite: false,
  	speed: 300,
  	slidesToShow: 4,
  	slidesToScroll: 3,
  	responsive: [
  	{
  	  breakpoint: 767,
  	  settings: {
  		dots: true,
      arrows: false,
  		slidesToShow: 1,
  		slidesToScroll: 1,
  		//centerMode: true,
      adaptiveHeight: true,
  	  }
  	}
  	]
  });

/*
  // homepage slideshow
  $("#home-intro-slideshow").slick({
  	dots: false,
  	arrows: false,
  	//infinite: true,
  	speed: 1000,
  	slidesToShow: 1,
  	slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    draggable: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    swipe: false,
    touchMove: false,
  });
*/

})(jQuery);
