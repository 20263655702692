/*
Podcast
*/
(function($) {

  // Check if elements with specified classes exist
  if ($('.pod-content__episode.episode-single').length) {
      // Wait for 3 seconds (3000 milliseconds)
      setTimeout(function() {
          // Apply display block style
          $('.pod-content__episode.episode-single').css('display', 'block');
      }, 2500);
  }

})(jQuery);
