(function($) {

  /* reveal ui - used on Showcase template */
  // setup

  var $showcase = $('#showcase-content');
  $showcase.find('.item-info').hide();

  // show/hide
  $showcase.find('.item').each( function() {

    // click action
    $(this).click(function(evt) {

      evt.preventDefault();
      var target = $(this).data('target');
      var status;
      var overlay = $(this).find('.overlay');

      if ( $(this).hasClass('open') )
      {

        //overlay.removeClass('fadeOut').addClass('fadeIn');
        $(this).removeClass('open').parent().removeClass('active');

        $('#'+target).slideUp();

      } else {

        $showcase.find('.item.open').removeClass('open').parent().removeClass('active');
        $showcase.find('.item-info:visible').slideUp();
        //$showcase.find('.overlay.fadeOut').removeClass('fadeOut').addClass('fadeIn');
        //overlay.removeClass('fadeIn').addClass('fadeOut');
        $(this).addClass('open').parent().addClass('active');
        $('#'+target).slideDown();

      }

    });

  });

  // close
  $showcase.find('.close').each( function() {

    $(this).click(function(evt) {

      evt.preventDefault();
      var target = $(this).data('target');
      //$showcase.find('.overlay.fadeOut').removeClass('fadeOut').addClass('fadeIn');
      $('#item-'+target).slideUp();
      $('#toggle-'+target).removeClass('open').parent().removeClass('active');

    });

  });


})(jQuery);
