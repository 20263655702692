/*
Mobile Nav toggle
/header.php
*/
(function($) {

/*
  var $mobile_toggle = $('#mobile-menu-toggle');
  var $mobile_menu = $('#mobile-menu');

  $mobile_toggle.click(function(evt) {
    evt.preventDefault();
    $(this).toggleClass('on');
    $mobile_menu.toggleClass('show');
  });
*/

  // modal - fix scrolling issue for iphone
  $('#search-modal').on('closed.zf.reveal', function() {
    $('body').removeClass('is-reveal-open');
    $('html').removeClass('is-reveal-open');
  });
  $('#mobilenav-modal').on('closed.zf.reveal', function() {
    $('body').removeClass('is-reveal-open');
    $('html').removeClass('is-reveal-open');
  });

  // search modal - focus on search input on modal load
   var escKey = 27;
   var $formSearchModalTrigger = $('#search-toggle-mobile, #search-toggle-desktop');
   var $formSearchModal = $('#search-modal');

   // close search modal
   function clearSearch() {
     $formSearchModal.removeClass('show');
   }

   // show search modal
   $formSearchModalTrigger.on('touchstart click', function(e) {
     e.preventDefault();
     $formSearchModal.addClass('show').find('.search-input').focus();
   });

  // close search modal with click/touch background
  $formSearchModal.on('touchstart click', function(e) {
    if ( $(e.target).hasClass('search-input') || $(e.target).hasClass('search-label') ) {
      // keep open for label and input
    } else {
      clearSearch();
    }
  });

  // close search modal with keyboard esc key
  $(document).keydown(function(e) {
    if ( e.which === escKey ) {
      if ( $formSearchModal.hasClass('show') ) {
        clearSearch();
      }
    }
  });


})(jQuery);
