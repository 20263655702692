/*
Animate CSS plugin
https://github.com/daneden/animate.css
$('#yourElement').animateCss('bounce');
*/

(function($) {

  $.fn.extend({
      animateCss: function (animationName) {
          var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
          this.addClass('animated ' + animationName).one(animationEnd, function() {
              $(this).removeClass('animated ' + animationName);
          });
          return this;
      }
  });

})(jQuery);

//$('.hdr-special').animateCss('zoomIn');
